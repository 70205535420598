interface Company {
  name: string;
  image: string;
  url: string;
}

export const listOfHostedAtCompanies: Company[] = [
  {
    name: 'Facebook',
    image: 'facebook.png',
    url: 'https://facebook.com'
  },
  {
    name: 'Spotify',
    image: 'spotify.svg',
    url: 'https://spotify.com'
  },
  {
    name: 'Netflix',
    image: 'netflix.png',
    url: 'netflix.com'
  },
  {
    name: 'CD Projekt RED',
    image: 'cdpr.png',
    url: 'cdprojektred.com'
  },
  {
    name: 'Ikea',
    image: 'ikea.svg',
    url: 'https://ikea.com'
  },
  {
    name: 'Santander',
    image: 'santander.png',
    url: 'https://santander.pl'
  },
  {
    name: 'Ergo',
    image: 'ergo.svg',
    url: 'ergo.com'
  },
  {
    name: 'TD Bank',
    image: 'tdbank.svg',
    url: 'td.com'
  }
  // {
  //   name: 'Akamai',
  //   image: 'akamai.svg',
  //   url: 'akamai.com'
  // },
  // {
  //   name: 'Grammarly',
  //   image: 'grammarly.svg',
  //   url: 'grammarly.com'
  // },
  // {
  //   name: 'Discovery',
  //   image: 'discovery.png',
  //   url: 'https://discovery.com'
  // },
];
