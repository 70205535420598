import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Lightbox from 'yet-another-react-lightbox';
// eslint-disable-next-line import/no-unresolved
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import { NextSlider } from '../../../components/NextSlider';
import { nextAsset } from '../../../components/nextAsset';
import style from './IndexGallerySlider.module.scss';

const IndexGallerySliderElement: React.FC<{ src: string; onClick: () => void }> = ({ src, onClick }) => {
  const intl = useIntl();
  return (
    <div onClick={onClick} onKeyDown={onClick} role="button" aria-hidden className={style.element}>
      <img
        src={nextAsset(src, false, { w: 800 })}
        className={classNames('img-fluid', style.elementImage)}
        alt={intl.formatMessage({
          id: 'web.app.name',
          defaultMessage: 'Quizado'
        })}
      />
    </div>
  );
};
export const IndexGallerySlider: React.FC<{ slides: { src: string; image: string }[] }> = React.memo(({ slides }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const onClick = useCallback(
    (i: number) => () => {
      setIndex(i);
      setIsOpen(true);
    },
    []
  );

  return (
    <>
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        index={index}
        slides={slides}
        plugins={[Thumbnails as any]}
      />
      <NextSlider
        lazyHeight={285}
        arrows={false}
        dots={false}
        centerMode
        autoplay
        className="center talking-about-us-slider mt-4"
        infinite
        centerPadding="60px"
        slidesToShow={1}
        swipeToSlide
        responsive={[
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
              centerPadding: '100px'
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              centerPadding: '100px'
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              centerPadding: '30px'
            }
          }
        ]}
      >
        {slides.map((image, i) => (
          <IndexGallerySliderElement key={image.image} src={image.image} onClick={onClick(i)} />
        ))}
      </NextSlider>
    </>
  );
});
