import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { listOfHostedAtCompanies } from './listOfHostedAtCompanies';
import { nextAsset } from '../../../components/nextAsset';
import styles from './IndexHostedAt.module.scss';

export const IndexHostedAt: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div className="bg-accent2">
      <Container className="my-5 py-5">
        <Row>
          <Col xs={12} sm={3} className="d-flex justify-content-center align-items-center flex-column">
            <h2 className={classNames(styles.title, 'text-center text-sm-left')}>
              {intl.formatMessage(
                {
                  id: 'web.home.hosted-at.loved_by',
                  defaultMessage:
                    'Loved by <span>35,000+ managers</span> & <span>1,000,000+ employees</span> at companies like:'
                },
                { span: (children: any) => <span>{`${children}`.replaceAll(' ', ' ')}</span> }
              )}
            </h2>
          </Col>
          <Col xs={12} sm={9}>
            <div className={styles.container}>
              {listOfHostedAtCompanies.map(({ name, url, image }) => (
                <a
                  href={url}
                  className={styles.element}
                  key={image}
                  style={{ backgroundImage: `url("${nextAsset(`images/hostedAt/${image}`)}")` }}
                >
                  {name}
                </a>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
});
