import React from 'react';
import { NextPage } from 'next';
import { IndexTop } from './index/IndexTop';
import { Footer } from '../../layouts/Footer';
import { IndexHostYourOwnGame } from './index/IndexHostYourOwnGame';
import { IndexDownloadNow } from './index/IndexDownloadNow';
import { IndexHostedAt } from './index/IndexHostedAt';
import { FeaturesDescription } from './features/FeaturesDescription';

export const Home: NextPage = React.memo(() => {
  return (
    <>
      <IndexTop />
      <IndexHostedAt />
      <IndexHostYourOwnGame />
      <IndexDownloadNow bgBottomColor="accent2" />
      <FeaturesDescription />
      <IndexDownloadNow bgTopColor="accent2" />
      <Footer />
    </>
  );
});
