import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons/faMobileAlt';
import { faWifiSlash } from '@fortawesome/pro-solid-svg-icons/faWifiSlash';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons/faHourglassHalf';
import { faPalette } from '@fortawesome/pro-solid-svg-icons/faPalette';
import { faCopyright } from '@fortawesome/pro-solid-svg-icons/faCopyright';
import classNames from 'classnames';
import styles from './FeaturesDescription.module.scss';
import { FeaturesDescriptionElement, FeaturesDescriptionElementType } from './FeaturesDescriptionElement';
import style from '../index/IndexHostYourOwnGame.module.scss';

export const FeaturesDescription: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div className="bg-accent2">
      <Container className="py-5">
        <Row>
          <Col xs={12}>
            <h2 className={classNames(styles.title, 'text-heading text-black text-shadow-accent2 text-center')}>
              {intl.formatMessage(
                {
                  id: 'web.home.features.title',
                  defaultMessage: 'Game <b>Features</b>'
                },
                {
                  b: (children: any) => (
                    <span className={classNames('text-shadow-none', style.textStroke)}>{children}</span>
                  ),
                  br: <br />
                }
              )}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={4} className="px-0 px-md-3">
            <FeaturesDescriptionElement type={FeaturesDescriptionElementType.Walrus} icon={faQuestion}>
              {intl.formatMessage(
                {
                  id: 'web.home.features.descriptions.list.own-questions',
                  defaultMessage: 'Create your own <b>questions</b> and personalize your experience.'
                },
                {
                  b: (children: any) => <strong>{children}</strong>
                }
              )}
            </FeaturesDescriptionElement>
          </Col>
          <Col xs={6} md={4} className="px-0 px-md-3">
            <FeaturesDescriptionElement type={FeaturesDescriptionElementType.Giraffe} icon={faMobileAlt}>
              {intl.formatMessage(
                {
                  id: 'web.home.features.descriptions.list.phone-as-remote-buzzer',
                  defaultMessage:
                    'Use your <b>phone as a remote</b> to control the game, and another <b>phone as a buzzer</b>.'
                },
                {
                  b: (children: any) => <strong>{children}</strong>
                }
              )}
            </FeaturesDescriptionElement>
          </Col>
          <Col xs={6} md={4} className="px-0 px-md-3">
            <FeaturesDescriptionElement type={FeaturesDescriptionElementType.Antilope} icon={faUsers}>
              {intl.formatMessage(
                {
                  id: 'web.home.features.descriptions.list.configure-teams',
                  defaultMessage: 'Configure any <b>number</b> of teams.'
                },
                {
                  b: (children: any) => <strong>{children}</strong>
                }
              )}
            </FeaturesDescriptionElement>
          </Col>
          <Col xs={6} md={4} className="px-0 px-md-3">
            <FeaturesDescriptionElement type={FeaturesDescriptionElementType.Crocodile} icon={faWifiSlash}>
              {intl.formatMessage(
                {
                  id: 'web.home.features.descriptions.list.play-offline',
                  defaultMessage: 'Play <b>offline</b> (when you have limited or no Internet access).'
                },
                {
                  b: (children: any) => <strong>{children}</strong>
                }
              )}
            </FeaturesDescriptionElement>
          </Col>
          <Col xs={6} md={4} className="px-0 px-md-3">
            <FeaturesDescriptionElement type={FeaturesDescriptionElementType.Elephant} icon={faPalette}>
              {intl.formatMessage(
                {
                  id: 'web.home.features.descriptions.list.themes',
                  defaultMessage: 'Pick one of two <b>themes</b>: modern or retro look.'
                },
                {
                  b: (children: any) => <strong>{children}</strong>
                }
              )}
            </FeaturesDescriptionElement>
          </Col>
          <Col xs={6} md={4} className="px-0 px-md-3">
            <FeaturesDescriptionElement type={FeaturesDescriptionElementType.Sloth} icon={faHourglassHalf}>
              {intl.formatMessage(
                {
                  id: 'web.home.features.descriptions.list.pool-of-questions',
                  defaultMessage:
                    'Play from the pool of <b>150 survey</b> questions. This accounts for over <b>500 hours</b> of entertainment!'
                },
                {
                  b: (children: any) => <strong>{children}</strong>
                }
              )}
            </FeaturesDescriptionElement>
          </Col>
          <Col xs={6} md={4} className="px-0 px-md-3">
            <FeaturesDescriptionElement type={FeaturesDescriptionElementType.Walrus} icon={faCopyright}>
              {intl.formatMessage(
                {
                  id: 'web.home.features.descriptions.list.upload-logo',
                  defaultMessage: 'Upload your own logo and <b>advertise your brand.</b>'
                },
                {
                  b: (children: any) => <strong>{children}</strong>
                }
              )}
            </FeaturesDescriptionElement>
          </Col>
        </Row>
      </Container>
    </div>
  );
});
