import React, { ReactElement } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FeatureWalrus from '../../../../public/statics/svgs/feature-walrus.svg';
import FeatureGiraffe from '../../../../public/statics/svgs/feature-giraffe.svg';
import FeatureAntilope from '../../../../public/statics/svgs/feature-antilope.svg';
import FeatureCrocodile from '../../../../public/statics/svgs/feature-crocodile.svg';
import FeatureElephant from '../../../../public/statics/svgs/feature-elephant.svg';
import FeatureSloth from '../../../../public/statics/svgs/feature-sloth.svg';
import style from './FeaturesDescriptionElement.module.scss';

export enum FeaturesDescriptionElementType {
  Walrus = 1,
  Giraffe = 2,
  Antilope = 3,
  Crocodile = 4,
  Elephant = 5,
  Sloth = 6
}

const images: Record<FeaturesDescriptionElementType, ReactElement> = {
  [FeaturesDescriptionElementType.Walrus]: <FeatureWalrus />,
  [FeaturesDescriptionElementType.Giraffe]: <FeatureGiraffe />,
  [FeaturesDescriptionElementType.Antilope]: <FeatureAntilope />,
  [FeaturesDescriptionElementType.Crocodile]: <FeatureCrocodile />,
  [FeaturesDescriptionElementType.Elephant]: <FeatureElephant />,
  [FeaturesDescriptionElementType.Sloth]: <FeatureSloth />
};

const styles: Record<FeaturesDescriptionElementType, string> = {
  [FeaturesDescriptionElementType.Walrus]: style.imageWalrus,
  [FeaturesDescriptionElementType.Giraffe]: style.imageGiraffe,
  [FeaturesDescriptionElementType.Antilope]: style.imageAntilope,
  [FeaturesDescriptionElementType.Crocodile]: style.imageCrocodile,
  [FeaturesDescriptionElementType.Elephant]: style.imageElephant,
  [FeaturesDescriptionElementType.Sloth]: style.imageSloth
};
export const FeaturesDescriptionElement: React.FC<
  React.PropsWithChildren<{ type: FeaturesDescriptionElementType; icon: IconDefinition }>
> = React.memo(({ children, type, icon }) => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column h-100 py-5 overflow-hidden">
      <div className={classNames(style.container, styles[type])}>
        <div className={style.image}>{images[type]}</div>
        <div className={style.icon}>
          <FontAwesomeIcon icon={icon} className="m-0" />
        </div>
      </div>
      <div className={style.label}>{children}</div>
    </div>
  );
});
