import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import classNames from 'classnames';
import style from './IndexHostYourOwnGame.module.scss';
import Crocodile1 from '../../../../public/statics/svgs/crocodile-1.svg';
import Sloth4 from '../../../../public/statics/svgs/sloth-4.svg';
import { IndexGallerySlider } from './IndexGallerySlider';
import { offlineSlides, onlineSlides } from './slides';

export const IndexHostYourOwnGame: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div className={style.container}>
      <Container className="position-relative py-5">
        <div className={style.header}>
          <div className={classNames('d-none d-lg-block', style.crocodile)}>
            <Crocodile1 />
          </div>
          <h2 className="text-heading text-black text-shadow-accent2 text-center">
            {intl.formatMessage(
              {
                id: 'web.home.host-your-own-game.title',
                defaultMessage: 'Host your own <b>game</b>'
              },
              {
                b: (children: any) => (
                  <span className={classNames('text-shadow-none', style.textStroke)}>{children}</span>
                ),
                br: <br />
              }
            )}
          </h2>
          <div className={classNames('d-none d-lg-block', style.sloth)}>
            <Sloth4 />
          </div>
        </div>
        <Row>
          <Col xs={12} sm={6}>
            <h2 className="text-heading text-black text-center mt-5">
              {intl.formatMessage({
                id: 'web.home.host-your-own-game.online.title',
                defaultMessage: 'Online'
              })}
            </h2>
            <IndexGallerySlider slides={onlineSlides} />
          </Col>
          <Col xs={12} sm={6}>
            <h2 className="text-heading text-black text-center mt-5">
              {intl.formatMessage({
                id: 'web.home.host-your-own-game.in_real_life.title',
                defaultMessage: 'Or in real life'
              })}
            </h2>
            <IndexGallerySlider slides={offlineSlides} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-5">
            <ul className={style.list}>
              <li>
                {intl.formatMessage(
                  {
                    id: 'web.home.host-your-own-game.sleek-graphics',
                    defaultMessage:
                      'Sleek graphics, cool animations and fun sound effects make for a true <b>game show</b> <b>experience.</b>'
                  },
                  {
                    b: (children: any) => <span className={style.textStroke}>{children}</span>
                  }
                )}
              </li>
              <li>
                {intl.formatMessage(
                  {
                    id: 'web.home.host-your-own-game.ready-made-sets',
                    defaultMessage:
                      '<b>Ready-made</b> <b>sets of questions</b> or supply your own to suit your group of contestants.'
                  },
                  {
                    b: (children: any) => <span className={style.textStroke}>{children}</span>
                  }
                )}
              </li>
              <li>
                {intl.formatMessage(
                  {
                    id: 'web.home.host-your-own-game.feel-free-to-remove-logo',
                    defaultMessage:
                      'Feel free to get rid of our Quizado logo and <b>personalize</b> <b>the game</b> with your own company branding.'
                  },
                  {
                    b: (children: any) => <span className={style.textStroke}>{children}</span>
                  }
                )}
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
});
