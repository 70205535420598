import { nextAsset } from '../../../components/nextAsset';

const offlineImages = [
  'gallery/10.jpg',
  'gallery/assisted-living/1.jpg',
  'gallery/uni_lodz/1.jpg',
  'gallery/targi/1.jpeg',
  'gallery/assisted-living/2.jpg',
  'gallery/3.jpg',
  'gallery/krystian/1.jpg',
  'gallery/uni_lodz/3.jpg',
  'gallery/12.jpg',
  'gallery/uni_lodz/2.jpg',
  'gallery/6.jpg',
  'gallery/assisted-living/3.jpg'
];
export const offlineSlides = offlineImages.map((image) => ({
  src: nextAsset(image, false),
  image
}));
const onlineImages = [
  'gallery/screenSharingApps/hangouts.png',
  'gallery/screenSharingApps/slack.png',
  'gallery/screenSharingApps/teams.png',
  'gallery/screenSharingApps/zoom.png',
  'gallery/screenSharingApps/hangouts_2.png',
  'gallery/screenSharingApps/slack_2.png',
  'gallery/screenSharingApps/teams_2.png',
  'gallery/screenSharingApps/zoom_2.png',
  'gallery/screenSharingApps/hangouts_3.png',
  'gallery/screenSharingApps/slack_3.png',
  'gallery/screenSharingApps/teams_3.png',
  'gallery/screenSharingApps/zoom_3.png'
];
export const onlineSlides = onlineImages.map((image) => ({
  src: nextAsset(image, false),
  image
}));
